<template>
  <div v-if="$auth.ready()">
    <nav v-if="$auth.check()" class="navbar">
      <div class="navbar-start outer">
        <div class="navbar-item">
          <button class="button is-outlined" :class="socketConnected ? 'is-primary' : 'is-danger'">
            {{ albumCount }} albums, {{ trackCount }} tracks
          </button>
        </div>
      </div>
      <div class="navbar-end">
        <div class="navbar-item signout">
          <div class="signed_in_as">Signed in as {{ $auth.user().surname }} </div><button class="button" @click="logout">Sign out </button>
        </div>
      </div>
    </nav>
    <div id="app" class="container" >
      
      <div class="buttons is_grouped">
        <h1 class="title" :class="listening ? 'listening_blackout' : ''">
          <span v-if="radioOn">Radio PBT</span><span v-else>Project Blood Stream</span></h1>
        <router-link v-if="$auth.check()" class="button" :to="{name: 'Upload'}">+</router-link>
        <button  v-if="$auth.check()" class="button radio_toggle" :class="radioOn ? 'active' : ''" @click="openRadio" />
      </div>

      
      <div class="columns" v-if="$auth.user()">
        <div class="column" :class="[minimised ? 'minimised' : '', listening ? 'is-three-quarters-desktop is-half-tablet listening_blackout' : '']">
          <router-view :key="$route.fullPath" />
          <div v-if="$auth.check()">
            <Radio v-if="radioOn" />
            <Browse :key="browseKey" v-else :radioOn="radioOn" :incoming="socketData" :currentlyListening="listening" @albumLoaded="playAlbum" @albumSelected="openPlayer"
            @passEdit="editEntry" @refreshQueue="refreshQueue" :currentAlbum="currentAlbum" />
          </div>
          
        </div>
        <div v-if="listening" :class="minimised ? 'minimised' : ''" class="column is-half-tablet is-one-quarter-desktop" id="player_pane">
          <Album v-if="currentAlbum " :agents="agents" :queue="queue" :album="currentAlbum" @stopListening="closePlayer" @passEdit="editEntry"  @albumLoaded="playAlbum" @minimise="minimisePlayer" />
          <div class="loading has-text-centered" v-else>
            <img src="@/assets/images/loading.gif" />
          </div>
        </div>
      </div>
      <Login v-else @socketConnect="connectToSocket" />
    </div>
  </div>
  <div class="modal edit_pane" :class="editing ? 'is-active' : false">
    <div class="modal-background"></div>
    
    <div class="modal-content">
      <EditAlbum :albumId="editing" @closeEditor="getCloseEditEvent" />
    </div>
    <button @click="editing = false" class="modal-close is-large" aria-label="close"></button>
  </div>
</template>

<script>
import Album from '@/components/Album'
import Browse from '@/components/Browse'
import Login from '@/components/Login'
import Radio from '@/components/Radio'
import EditAlbum from '@/components/EditAlbum'

export default {
  name: 'App',
  components: {
    Browse,
    Login,
    Album,
    EditAlbum,
    Radio
  },
  data() {
    return {
      radioOn: false,
      albumCount: 0,
      trackCount: 0,
      socketConnected: false,
      browseKey: 'initial',
      listening: undefined,
      currentAlbum: null,
      socketData: null,
      headers: {},
      editing: null,
      queueTop: {},
      queue: [],
      minimised: false,
      agents: undefined
    }
  },
  channels: {
    StreamChannel: {
      connected() {
        // console.log("I am connected.");
        this.socketConnected = true
      },
      disconnected() {
        this.socketConnected = false
      },
      received(data) {
        // console.log('got data from channel: ' )
        // console.log(data)
        if (data.tracks_count) {
          this.trackCount = data.tracks_count
        } else if (data.albums_count) {
          this.albumCount = data.albums_count
        } else  {
          this.socketData = data
        }
      }
    },
  },
  methods: {
    openRadio() {
      this.radioOn = !this.radioOn
      if (this.radioOn) {
        this.closePlayer()
      }
    },
    minimisePlayer (state) { 
      this.minimised = state
    },
    refreshQueue () {
      this.queue = localStorage.getItem('queue')
    },
    async connectChannel (headers) {
      // console.log('trying to connect to ' + process.env.VUE_APP_CABLE_URL)
      await this.$cable.connection.connect(() => `${ process.env.VUE_APP_CABLE_URL }?uid=${ headers.uid }&client=${ headers.client }&access-token=${ headers['access-token'] }` )
      await this.$cable.subscribe({ channel: 'StreamChannel' })
    },
    connectToSocket(headers) {
      this.headers = headers
      // this.setupBloodstream()
      this.connectChannel(headers)
    },
    closePlayer() {
      this.listening = false
      this.currentAlbum = undefined
      this.queueTop = {}
      localStorage.removeItem('queueTop')
    },
    editEntry(albumId) {
      this.editing = albumId
    },
    getCloseEditEvent(arg, justEdited) {
      this.editing = false
      if (arg === 'deleted') {
        // console.log('deleted ', justEdited)
      }
      if (arg === 'edited') {
        // console.log('just edited id ' + justEdited)
        // get new value and send through socket
         this.axios.get('/v1/albums/' + justEdited)
          .then((response) => {
            if (response.status === 200) {
              this.socketData = JSON.stringify(response.data)
              // console.log('just set socketData to: ')
              // console.log(JSON.stringify(response.data))
            }
          })
      }
    },
    openPlayer(value) {
      // console.log('setting listening to ' + value)
      this.listening = value
    },
    playAlbum (value) {
      // console.log('entering playalbum from ' + source)
      this.listening = true
      // console.log('album playing:')
      // console.log(value)
      // check queueTop first
      let qt = localStorage.getItem('queueTop')
      if (value) {
        if (qt) {
          qt = JSON.parse(qt)
          if (qt.albumId === value.data.id) {
            console.log('already loaded this album')
            // do nothing
          } else {
            qt = { albumId: value.data.id, track: value.data.relationships.tracks.data[0].id }
          }
          this.queueTop = qt
          localStorage.setItem('queueTop', JSON.stringify(qt))
        }
        
      }
      this.currentAlbum = value
    },
    setupBloodstream () {
      if (!this.agents) {
        this.axios.get('/v1/agents')
          .then((agents) => {
            this.agents = agents.data.data
          })
      }
      this.queueTop = localStorage.getItem('queueTop')
      // console.log(this.queueTop)
      if (this.queueTop) {
        // console.log('should open ' +  JSON.parse(this.queueTop).albumId)
        this.fetchAlbum(JSON.parse(this.queueTop).albumId, 'setupBS queueTop')
      }
      this.axios.get('/v1/albums/stats')
        .then((resp) => {
          this.trackCount = resp.data.tracks
          this.albumCount = resp.data.albums
        })   
    },
    secondsToHms(d) {
      let measuredTime = new Date(null)
      measuredTime.setSeconds(d)
      return measuredTime.toISOString().substr(11, 8)
    },
    logout() {
      this.$auth
        .logout({
          makeRequest: true,
          redirect: {name: 'auth-login'},
        });
    },
    fetchAlbum(albumId) {
      this.axios.get('/v1/albums/' + albumId)
          .then((response) => {
            if (response.status === 200) {
              this.playAlbum(response.data)
              // console.log('opening player for album ' + albumId)
              this.openPlayer(true)
            }
          })
          .catch((err) => {
            // console.log(err)
            this.$toast.error('Error updating track metadata: ', err.response.data.message, {
              timeout: 4000
            })
        })
    }
  },
  watch: {
    $route() {
      this.$nextTick(this.routeLoaded);
      let toOpen = null
      if (this.$route.query.openAlbum) {
        if (!this.currentAlbum) {
          toOpen = this.$route.query.openAlbum
        }
      } else {
        let lsQueue = localStorage.getItem('queue')
        let lsQt = localStorage.getItem('queueTop')
       
        if (lsQueue && !lsQt) {     
          lsQueue = JSON.parse(lsQueue)
          if (lsQueue.length > 0) {
            toOpen = lsQueue[0].id
            lsQueue.shift()
            localStorage.setItem('queue', JSON.stringify(lsQueue))
          }
        }
      }
      if (toOpen) {
        this.fetchAlbum(toOpen, 'toOpen route watch')
      }
    }
  },
  mounted () { 

    this.$auth.load()
      .then(() => {
        if (this.$auth.ready()) {
          this.setupBloodstream()
          this.$cable.connection.disconnect()
          let freshTokens = this.$auth.token().split('|')
          this.connectToSocket({ "access-token": freshTokens[1], client: freshTokens[2], uid: freshTokens[3] })
        } else {
          // console.log('not signed in')
        }
      })
  }
}
</script>

<style lang="scss">
@import 'assets/css/bloodstream.scss';
</style>