import { createApp } from 'vue'
import App                   from './App.vue'
import http        from './http'
// import store       from './store'
import router      from './router'
import auth        from './auth.js'
import ActionCableVue from 'actioncable-vue'
import Toast from 'vue-toastification'
import { useToast } from 'vue-toastification'
import 'vue-toastification/dist/index.css' 
import '@vime/core/themes/default.css'
// import '@vime/core/themes/light.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'

library.add(faExternalLinkAlt)


import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
const actionCableVueOptions = {
  debug: true,
  debugLevel: 'error',
  connectImmediately: false
}

const toast = useToast()
const app = createApp(App)

app.config.globalProperties.$moment = moment;
app.config.globalProperties.$toast = toast
app
  .use(http)
  // .use(store)
  .use(router)
  .use(Toast)

  .component('font-awesome-icon', FontAwesomeIcon)
  .use(ActionCableVue, actionCableVueOptions)
  .use(auth)
  .mount('#app')
