<template>
  <tr v-if="track" :class="[nowPlaying ? 'playing' : '', track.attributes.audited === -1 ? 'missing' : '', track.attributes.badformat ? 'badformat' : '']" @click='playTrack(track.id)'>
    <td v-if="currentAlbum"><span v-if="currentAlbum.attributes.number_of_discs > 1">{{ track.attributes.discNumber }}&sdot;</span>{{ track.attributes.trackNumber }}</td>
    <td class="artist" v-if="!currentAlbum || currentAlbum.attributes.is_comp">
      <span v-if="nowPlaying" class="play_arrow">&#9658; </span>{{ track.attributes.artist }}</td>
    <td class="song">
      <span v-if="nowPlaying && !currentAlbum.attributes.is_comp" class="play_arrow">&#9658; </span>
      {{ track.attributes.name }}</td>
    <td class="time is-size-7" v-if="track.attributes.bitRate">
      {{ secondsToHms(track.attributes.trackLength) }}, {{ track.attributes.bitRate }}k
    </td>
    <td class="flac" v-if="track.attributes.lossless">
      <img  src="@/assets/images/lossless.png" />
    </td>
    <td class="dl" v-if="dl">
      <a :href="track.attributes.songfile" class="button is-small">&darr;</a>
    </td>
  </tr>

</template>

<script>
export default {
  props: {
    currentAlbum: Object,
    nowPlaying: Boolean,
    track: Object,
    dl: Boolean
  },
  emits: [ 'playTrack'],
  methods: {
    playTrack(e) {
      console.log('emitting from Track up')
      this.$emit('playTrack', e)
    },
    secondsToHms(d) {
      let measuredTime = new Date(null)
      measuredTime.setSeconds(d)
      return measuredTime.toISOString().substr(11, 8)
    }
  }
}
</script>