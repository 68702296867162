<template>
  <div class="container" v-if="$auth.user()">
    <div class="field ">

      <input class="input search" v-model="search" type="search" placeholder="Search...."><button v-if="search" @click="clearSearch" class="is-primary button">Clear</button>
      <br /><input type="checkbox"  @change="serverSearch(search)" v-model="includeTracks" /> Search track names also&nbsp;&nbsp;&nbsp;
      <input type="checkbox"  @change="serverSearch(search)" v-model="fuzzySearch" /> Use 'fuzzy' search
    </div>
    <nav class="navbar browse">
      

      <div class="buttons is-grouped"  v-bind:class="{'is-active': isOpen}">
        
        <button class="button" v-for="filter in Object.keys(filters)" :key="filter" @click="setFilter(filter)"  :class="selectedFilter === filter ? 'is-primary' : ''">
            {{ filters[filter] }}&nbsp;<span v-if="filter === 'by_random'">&#8635;</span><span v-else-if="asc">&#8593;</span><span v-else>&#8595;</span>
          </button>
 
      </div>
    </nav>

    <section class="section">
      <div class="loading has-text-centered" v-if="searching">
        <img src="@/assets/images/loading.gif" />
      </div>
      <div v-else class="columns album_main_browse is-multiline is-mobile">
        <div class="column" v-if="noResults">
          There are no matches for your search. Please try again.
        </div>
        <div v-else class="column is-half-mobile is-one-quarter-desktop album_browser" :class="(currentAlbum && currentAlbum.data && currentAlbum.data.id) == album.id ? 'active_listening' : ''" v-for="album in albums" :key="album.id">
          <figure @click="loadAlbum(album.id, 'initial')" class="image is-square">
            <img v-if="album.attributes.thumbnail_cover" :src="album.attributes.thumbnail_cover" />
            <img v-else src="@/assets/images/missing.jpg" />
          </figure>        
          <h2 class="artist">{{ album.attributes.display_artist }}</h2>
          <h2 @click="loadAlbum(album.id, 'initial')" class="album"> {{ album.attributes.name }} </h2><span class="tag is-primary" v-if="album.justAdded">Just added!</span>
          <h4 class="year"><span v-if="album.attributes.year">{{ album.attributes.year }}&nbsp;&sdot;&nbsp;</span><span v-if="album.attributes.number_of_discs > 1">{{ album.attributes.number_of_discs }} discs&nbsp;&sdot;&nbsp;</span><span v-if="album.attributes.tracks_count">{{album.attributes.tracks_count }}&nbsp;<span v-if="album.attributes.tracks_count > 1">tracks</span><span v-else>track</span></span>
          <span v-if="album.attributes.has_lossless">
            &nbsp;&nbsp; <img class="flac_icon" title="Lossless/flac" src="@/assets/images/lossless.png" />
          </span>
          <span v-if="album.attributes.discogs_master_id || album.attributes.discogs_release_id">
            <span v-if="!album.attributes.has_lossless">&nbsp;&sdot;</span>&nbsp;
            <a v-if="parseInt(album.attributes.discogs_master_id) > 0" :href="'https://www.discogs.com/master/' + album.attributes.discogs_master_id" target="_blank" ><font-awesome-icon icon="external-link-alt" /></a>
            <a v-else-if="parseInt(album.attributes.discogs_release_id) > 0" :href="'https://www.discogs.com/release/' + album.attributes.discogs_release_id"
              target="_blank"><font-awesome-icon icon="external-link-alt" />
            </a>
                
            </span>
          </h4>
          <div class="buttons is-grouped">
            <button class="edit_album button is-small" @click="editEntry(album.id)">Edit</button>
            <button v-if="currentlyListening" class="edit_album button is-small" @click="queueAlbum(album.id)">Queue</button>
            <button v-else class="edit_album button is-small" @click="previewInModal(album.id)">Preview</button>
          </div>
        
          
        </div>
      </div>
      <div class="loading has-text-centered" v-show="loadingMore">
        <img src="@/assets/images/loading.gif" />
      </div>
  </section>
  <section class="section">
    <div class="modal preview_pane" :class="previewOpened ? 'is-active' : false">
      <div class="modal-background"></div>
      
      <div class="modal-content">
        <button @click="previewOpened = false" class="delete is-large"></button>
        <h1>Previewing....</h1>
        <div v-if="previewAlbum.id"  @keydown.esc="previewOpened = false" tabindex="0">
          <h1 class="title">{{ previewAlbum.attributes.display_artist }}</h1>
          
          <h2 class="subtitle"><em>{{ previewAlbum.attributes.name }}</em> &sdot; {{ previewAlbum.attributes.year }}</h2>
          <div class="columns is-mobile ">
            <div class="column">
              <div class="buttons is-grouped">
                <button class="button is-outlined is-secondary" @click="loadAlbum(previewAlbum.id, 'fromModal')"><span v-if="currentlyListening">Switch</span><span v-else>Listen</span>&nbsp;to this album</button>
                <button v-if="currentlyListening" class="button is-secondary is-outlined" @click="queueAlbum(previewAlbum.id)">Add to Queue</button>
              </div>
            </div>
          </div>
          <div class="columns is-mobile ">
            <div class="column is-full">
              <figure class="image">
                <img v-if="previewAlbum.attributes.thumbnail_cover" :src="previewAlbum.attributes.thumbnail_cover" />
                <img v-else src="@/assets/images/missing.jpg" />
              </figure>
            </div>
          </div>
          <div class="columns is-mobile track_list">
            <div class="column is-full-mobile">

              <Tracklist  :nowPlaying="{id: null}" :currentAlbum="previewAlbum" v-if="previewTracks.length > 0"  :tracks="previewTracks" />
            </div>
            
              <!-- <table v-if="previewTracks.length > 0" class="table albumPlayer">
                <tr :class="nowPlaying == track ? 'playing' : ''" v-for="track in previewTracks" :key="track.id">
                  <td><span v-if="previewAlbum.attributes.number_of_discs > 1">{{ track.attributes.discNumber }}&sdot;</span>{{ track.attributes.trackNumber }}</td>
                  <td v-if="previewAlbum.attributes.is_comp">{{ track.attributes.artist }}</td>
                  <td>{{ track.attributes.name }}</td>
                  <td class="is-size-7" v-if="track.attributes.bitRate">
                    {{ secondsToHms(track.attributes.trackLength) }}, {{ track.attributes.bitRate }}k
                  </td>
                </tr>
              </table> -->
        
          </div>
        </div>
        <div v-else class="loading has-text-centered">
          <img src="@/assets/images/loading.gif" />
        </div>
      </div>

    </div>
  </section>
  </div>
</template>
<script>
import { useToast } from 'vue-toastification'
import normalize from 'json-api-normalizer'
import _ from 'lodash'
import Tracklist from '@/components/Tracklist'
export default {
  name: 'Browse',
  data() {
    return {
      albums: [], 
      cachedAlbums: [],
      page: 1,
      previewOpened: false,
      searching: true,
      previewAlbum: {},
      rawTracks: {},
      previewTracks: [],
      isOpen: false,
      search: '',
      isTyping: false,
      loadingMore: false,
      noResults: false,
      dataOver: false,
      allowScroll: true,
      includeTracks: false,
      fuzzySearch: false,
      selectedFilter: 'by_catalogued',
      filters: 
        { 
          by_random: 'Random',
          by_catalogued: 'Date catalogued',
          by_uploaded: 'Date uploaded',
          by_artist: 'Artist',
          by_year: 'Year' 
        },
      asc: false
      
    }
  },
  components: { Tracklist },
  emits: ['albumLoaded', 'albumSelected', 'passEdit', 'refreshQueue'],
  props: ['incoming', 'currentlyListening', 'radioOn', 'currentAlbum'],
  watch: {
    radioOn: function(value) {
      alert(value)
      if (value) {
        if (this.cachedAlbums.length === 0) {
          this.cachedAlbums = this.albums
        }
        this.allowScroll = false
        // console.log('removing scroll listener')
        window.removeEventListener('scroll', this.scroll())
      } else {
        if (this.cachedAlbums.length > 0) {
          this.albums = this.cachedAlbums
          this.cachedAlbums = []
        }
        // console.log('adding scroll listener')
        this.allowScroll = true
        window.addEventListener('scroll', this.scroll())
      }
    },
    currentlyListening: function(value) {
      if (!value) {
        this.previewOpened = false
        this.previewAlbum = {}
      }
    },
    incoming: function(value) {

      if (value) {
        console.log('incoming data: ' ) 
        
        let jsonAlbum = JSON.parse(this.incoming)
        // console.log(jsonAlbum)
        // check to see if in existing albums array and update
        console.log('checking for ' + parseInt(jsonAlbum.data.id))
        console.log('against:')
        console.log(this.albums.map(i => parseInt(i.id)))
        let albumIndex = this.albums.findIndex(i => parseInt(i.id) == parseInt(jsonAlbum.data.id))
        console.log('found index at ' + albumIndex)
        if ((albumIndex === 0 || albumIndex > 0)  && albumIndex !== -1) {
     
          this.albums[albumIndex] = jsonAlbum.data
          this.albums[albumIndex].justAdded = true          
        } else {
          //  if not...
          console.log('not found so unshifting')
          console.log(jsonAlbum.data)
          this.albums.unshift(jsonAlbum.data)
          this.albums[0].justAdded = true
        }
      }
    },
    search: _.debounce(function() {
      this.isTyping = true;
      if (this.search === '') {
        if (this.noResults) {
          this.noResults = false
        }
        this.isTyping = false
        if (this.cachedAlbums.length > 0) {
          this.albums = this.cachedAlbums
          this.cachedAlbums = []
        } else {
          this.loadDefaultAlbums() 
        }
      }
    }, 600),
    isTyping: function(value) {

      if (value) {
        this.serverSearch(this.search);
        this.isTyping = false
      } else {
        // console.log('no value!')
      }
    }
  },
  created () {
    // console.log('adding event listener')
    window.addEventListener('scroll', this.scroll)
  },
  beforeUnmount () {
    // console.log('removing scroll listener')
    window.removeEventListener('scroll', this.scroll)
  },
  methods : {
    clearSearch() {
      this.search = ''

    },
    queueAlbum(albumId) {
      let queue = localStorage.getItem('queue')
      queue = queue ? JSON.parse(queue) : []
      let serializedAlbum = this.albums.find((i) => { return i.id == albumId })
      if (serializedAlbum) {
        
        // console.log('setting queue before serializtion it is: ')
        // console.log(queue)
        queue.push(serializedAlbum)
        //  console.log('setting queue after push it is: ')
        // console.log(queue)
      }
      localStorage.setItem('queue', JSON.stringify(queue))
      this.$emit('refreshQueue', true)
    },
    setFilter(filter) {
      if (this.selectedFilter === filter) {
        this.asc = !this.asc
        localStorage.setItem('filterDir', this.asc ? 'asc' : 'desc')
      } else {
        this.selectedFilter = filter
        this.page = 1
        localStorage.setItem('selectedFilter', filter)
      }
      this.searching = true
      this.loadDefaultAlbums()
    },
    loadDefaultAlbums () {
      
      let sortDir = localStorage.getItem('filterDir')
      if (!sortDir) {
        sortDir = 'desc'
      }
      this.axios.get('/v1/albums?' + this.selectedFilter + "=" + sortDir)
      .then((resp) => {
        this.albums = resp.data.data
        this.searching = false
      })
      .catch((error) => {
        const toast = useToast()
        toast.error('Error loading albums: ' + error.response.data.message, {
          timeout: 4000
        })
      })
    },
    serverSearch () {
      if (this.search.length >= 3) {
        this.searching = true;
        if (this.cachedAlbums.length === 0) {
          this.cachedAlbums = this.albums
        }
        let  url = `/v1/albums/search?search=${encodeURIComponent(this.search)}&page=1&include_tracks=${this.includeTracks ? 'true' : 'false'}&fuzzy_search=${this.fuzzySearch ? 'true' : 'false'}`
        this.axios.get(url)
          .then((hits) => {
            this.searching = false;
            if (hits.data.data.length > 0) {
              this.noResults = false
              this.albums = []
              hits.data.data.forEach((entry) => {
                this.albums.push(entry)
              })
            } else { 
              this.noResults = true
              this.isTyping = false
            }
          })
      }
		},
    prepareTracks (newAlbum) {
      this.rawTracks = normalize(newAlbum).track
      this.previewTracks = []
      Object.keys(this.rawTracks).forEach((t) => this.previewTracks.push(this.rawTracks[t]))

      this.previewTracks.sort((a, b) => (a.attributes.discNumber > b.attributes.discNumber) ? 1 : (a.attributes.discNumber  === b.attributes.discNumber ) ? ((a.attributes.trackNumber  > b.attributes.trackNumber ) ? 1 : -1) : -1)

    },
    previewInModal(albumId) {
      this.previewAlbum = {}
      this.previewOpened = true
      document.onkeydown = e => {
        if (e.keyCode === 27) {
          // console.log('escape key pressed browse')
          if (this.previewOpened) {
            this.previewOpened = false
            document.onkeydown = null
          }
        }
      }

      this.axios.get('/v1/albums/' + albumId)
        .then((resp) => {
          this.previewAlbum = resp.data.data
          this.prepareTracks(resp.data)
        })
        .catch((error) => {
          if (error.response.status === 404) {
            const toast = useToast()
            toast.error('Album not found. This may be a recently deleted album still appearing in search results for a few more minutes.', {
              timeout: 4000
            })
            this.previewOpened =  false
          }
        })
    },
    secondsToHms(d) {
      let measuredTime = new Date(null)
      measuredTime.setSeconds(d)
      return measuredTime.toISOString().substr(11, 8)
    },
    editEntry(albumId) {
      this.$emit('passEdit', albumId)
    },
    loadAlbum (albumId, state) {
      if (this.currentlyListening && state !== 'fromModal') {
        return this.previewInModal(albumId)
      } else {
        
        this.$emit('albumLoaded', null)
        this.$emit('albumSelected', true)
        this.axios.get('/v1/albums/' + albumId)
          .then((resp) => {
            if (resp.status === 200) {
              this.$emit('albumLoaded', resp.data)
              this.previewOpened = false
            } 
          })
          .catch((error) => {
            if (error.response.status === 404) {
              const toast = useToast()
              toast.error('Album not found. This may be a recently deleted album still appearing in search results for a few more minutes.', {
                timeout: 4000
              })
              this.$emit('albumSelected', false)
            }
            // console.log(error)
          })
      }
    },
    scroll () {
      window.onscroll = () => {
        let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight

        if (bottomOfWindow && !this.dataOver && !this.loadingMore) {
          // console.log('bottom of page from browse component')
          this.page++
          this.loadingMore = true
          let sortDir = localStorage.getItem('filterDir')
          if (!sortDir) {
            sortDir = 'desc'
          }
          let url = '/v1/albums?page=' + this.page + '&' + this.selectedFilter + '=' + sortDir
          if (this.search) {
            url = '/v1/albums/search?search=' + encodeURIComponent(this.search) + '&page=' + this.page  + '&include_tracks=' + (this.includeTracks ? 'true' : 'false')
          }
          this.axios.get(url)
            .then((response) => {
              if (response.data.data.length > 0) {
                response.data.data.forEach((entry) => {
                  this.albums.push(entry)
                })
                
              } else {
                this.dataOver = true
              }
              this.loadingMore = false
            })
          }
      }
    }
  },
  mounted () {
    // console.log('brready', this.$auth.ready())
    // console.log('brcheck', this.$auth.check())
    // console.log('bruser', this.$auth.user())
    this.$auth.load()
      .then(() => {
        if (localStorage.selectedFilter) {
          this.selectedFilter = localStorage.getItem('selectedFilter')
        }
        if (localStorage.filterDir) {
          this.asc = localStorage.getItem('filterDir')
        }
        if (this.cachedAlbums.length > 0) {
          this.albums = this.cachedAlbums
          this.cachedAlbums = []
        } else {
          // console.log('loading from mounted hook')
          this.loadDefaultAlbums()
        }
      })
    
  }
}
</script>