import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.interceptors.response.use(undefined, (err) => {
  const { config, message } = err
  if (!config || !config.retry) {
    return Promise.reject(err)
  }
  // retry while Network timeout or Network Error
  if (!(message.includes("timeout") || message.includes("403") || message.includes("Network Error"))) {
    return Promise.reject(err)
  }
  config.retry -= 1
  const delayRetryRequest = new Promise((resolve) => {
    setTimeout(() => {
      console.log("retry the request", config.url)
      resolve()
    }, config.retryDelay || 1000)
  })
  return delayRetryRequest.then(() => axios(config))
})

export default (app) => {
    app.axios = axios
    app.$http = axios

    app.config.globalProperties.axios = axios
    app.config.globalProperties.$http = axios
}