<template>
  <section class="main-content section container is-fullheight">
    <div class="container">
      <div class="columns">
        <div class="column is-one-third">
          <h1>Login</h1>
          {{ $auth.user() }}
          <form v-on:submit.prevent="login()">
              <div class="field">
                <label class="label">Email:</label>
                <input v-model="data.body.email" type="email" autocapitalize="off" autocomplete="off"
    spellcheck="false" 
    autocorrect="off" class="input"/>
              </div>
              <div class="field">
                <label class="label">Password:</label>
                <input v-model="data.body.password" class="input" type="password" />
              </div>
              <div class="field">
                <input v-model="data.rememberMe" type="checkbox" /><label class="label">Remember Me</label>
              </div>
              <div class="field">
                <div class="control">
                  <button class="button is-success" type="submit">Login</button>
                </div>
              </div>
            <div v-show="error" style="color:red; word-wrap:break-word;">{{ error  }}</div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useToast } from 'vue-toastification'
export default {
 
  data() {
    return {
      context: 'login context',
      data: {
        body: {
          email: '',
          password: ''
        },
        rememberMe: false,
        fetchUser: false
      },
      error: null
    };
  },
  name: 'Login',
  emits: ['socketConnect'], 
  mounted() {

  },
  methods: {

    login() {
      const toast = useToast()
      this.$auth
        .login({
            data: this.data.body,
            rememberMe: true,
            redirect: '/',
            fetchUser: false
        })
        .then((res) => {
          this.$auth.user(res.data.data)
          this.$emit('socketConnect', res.headers)
          console.log('emitting socketConnect: ')
         


        })
        .catch(() => {

          toast.error('Incorrect email and/or password!', {
            timeout: 4000
          })
          // this.data.body.email = ''
          this.data.body.password = ''
        })
    }
  }
}
</script>
