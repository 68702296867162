<template>
  <div class="columns radio">
    <div class="column is-narrow">
      <button class="button" :class="enableYearFilter ? 'is-primary' : 'is-secondary'" @click="enableYearFilter = !enableYearFilter">Limit years</button>
       <button class="button is-primary" @click="skipNext">Skip to next</button>
    </div>
    </div>
    <div v-show="enableYearFilter" class="column is-narrow">
      Only between years: <input type="text" v-model="yearFrom" /> and
      <input type="text" v-model="yearTo" />&nbsp;
      <button class="button is-small" @click="filterYears">Limit</button>
     
    <div v-show="enableYearFilter" class="column">&nbsp;</div>
    
  </div>
  <div class="columns radio">
    <div class="column is-one-quarter">
      <h4 class="subtitle">Recently played:</h4>
      <Tracklist v-if="played.length > 0"  :tracks="played"   :nowPlaying="nowPlaying" :currentAlbum="{attributes: {number_of_discs: 1, is_comp: true }}" @playTrack="playTrack" />
    </div>
    <div class="column now_playing is-one-half">
      <div v-if="nowPlaying">
        <h1 class="title">{{ nowPlaying.attributes.name }}</h1>
        <h2 class="subtitle attribution"> by {{ nowPlaying.attributes.artist }}</h2>
        <h3 class="subtitle is-size-6"> from 
          <span v-if="nowPlaying.attributes.is_comp">{{ nowPlaying.attributes.album_artist }} –– </span><em>{{ nowPlaying.attributes.album_name }}</em> <span v-if="nowPlaying.attributes.album_year">({{ nowPlaying.attributes.album_year }})</span>

          <p><a :href="'/?openAlbum=' + nowPlaying.attributes.album_id" class="button is-small is-secondary">Listen</a>
          <span v-if="nowPlaying.attributes.discogs_master_id || nowPlaying.attributes.discogs_release_id">
            &nbsp;&sdot;&nbsp;
            <a v-if="nowPlaying.attributes.discogs_master_id" :href="'https://www.discogs.com/master/' + nowPlaying.attributes.discogs_master_id" target="_blank" ><font-awesome-icon icon="external-link-alt" /></a>
            <a v-else  :href="'https://www.discogs.com/release/' + nowPlaying.attributes.discogs_release_id"
              target="_blank"><font-awesome-icon icon="external-link-alt" />
            </a>
                
          </span>
          
          </p>
        </h3>
        <img class="cover" v-if="nowPlaying.attributes.album_cover" :src="nowPlaying.attributes.album_cover" />
        <img class="cover" v-else src="@/assets/images/missing.jpg" />
        <Player v-if="nowPlaying" :key="nowPlaying.id" ref="pbtPlayer" controls autoplay
            @vmReady="onPlaybackReady"
            @vmPlaybackEnded="endOfTrack"
            @vmPlaybackReady="mediaReady"
            >
          <Audio preload>
            <source :data-src="nowPlaying.attributes.songfile" :type="nowPlaying.attributes.contentType" />
          </Audio>
          
        </Player> 
        
      </div>
      <div class="loading has-text-centered" v-else>
        <img class="loading" src="@/assets/images/loading.gif" />
      </div>
    
    </div>
    <div class="column is-one-quarter">


      <h3 class="subtitle">Up next:
        <button class="button  is-small is-secondary" @click="showQueue = !showQueue"><span v-if="showQueue">Hide </span><span v-else>Show </span>&nbsp;queue</button>
        
      </h3>
      <Tracklist :key="tracklistKey" v-show="showQueue" :nowPlaying="nowPlaying" :currentAlbum="{attributes: {number_of_discs: 1, is_comp: true }}" :tracks="queue" @playTrack="playTrack" />
    </div>
  </div>
</template>

<script>
import Tracklist from '@/components/Tracklist'
import { Player, Audio } from '@vime/vue-next'
export default {
  data () {
    return {
      played: [],
      tracklistKey: 0,
      queue: [],
      showQueue: true,
      nextTrackLoading: true,
      nowPlaying: undefined,
      prevTrack: {},
      yearFrom: new Date().getFullYear() - 1,
      yearTo: new Date().getFullYear(),
      enableYearFilter: false,
      filterParam: ''
    }
  },
  components: {
    Player,
    Tracklist,
    Audio,
  },
  methods: {
    filterYears () {
      let now = new Date().getFullYear()
      if (this.yearTo >= now) {
        this.yearTo = now
      } 
      if (this.yearFrom >= now) {
        this.yearFrom = now
      }
      if (this.yearTo < this.yearFrom) {
        this.yearTo = this.yearFrom
      }
      if (this.enableYearFilter) {
        this.filterParam = '&years=' + this.yearFrom + ',' + this.yearTo
      } else {
        this.filterParam  = ''
      }
      this.queue = []
      this.getNewTracks(3)
    },
    skipNext() {
      this.endOfTrack(true)
    },
    playTrack(e) {
   
      if (e) {
        console.log(e)
        this.played.unshift(this.nowPlaying)

        // better to just re-pull this specific track from the api in case the signed URL has expired
        this.axios.get('/v1/tracks/' + e)
          .then((resp) => {
            
            this.nowPlaying = resp.data.data
            this.queue = this.queue.filter((i) => i.id !== e )

            if (this.queue.length <= 2) {
              this.getNewTracks(3, true)
            }
          })
          .catch((err) => {
            this.$toast.error('Error getting track: ' + err.response.data.message)
          })
      }
    },
    mediaReady(f) {
      this.nextTrackLoading = false
      // console.log(f)
      if (f) {
        // console.log('starting next play')
        this.$refs.pbtPlayer.play()
        this.queue = this.queue.filter((i) => i.id !== this.nowPlaying.id )
        if (this.queue.length < 2) {
          this.getNewTracks(3, true)
        }
      }
    },
    onPlaybackReady () {
      // console.log('playback ready, start track')
      this.nextTrackLoading = false
      this.playTrack()
      // this.queue = this.queue.filter((i) => i.id !== this.nowPlaying.id )  

      if (this.queue.length <= 1) {
        // console.log('q lenfth is ' + this.queue.length)
        this.getNewTracks(3, true)
      }
    },
    endOfTrack(f) {
      if (f) {
        // console.log('emitted endoftrack naturally:')
        // console.log(f)
        
        this.nextTrackLoading = true
        this.played.unshift(this.nowPlaying)

        this.nowPlaying = this.queue[0]
        this.queue = this.queue.filter((i) => i.id !== this.nowPlaying.id )
      }
    },
    getNewTracks (limit = 3, background = false) {
      this.axios.get('/v1/tracks/random?limit=' + limit + this.filterParam)
        .then((resp) => {
          this.queue = this.queue.concat(resp.data.data)
          this.tracklistKey = new Date().getTime()
          if (!background) {
            this.nowPlaying = this.queue[0]

          }
          this.nextTrackLoading = false
        })
        .catch((err) => {
          this.$toast.error('Error getting tracks: ' + err.response.data.message)
        })
    }
  },
  mounted () {
    this.queue = []
    this.getNewTracks(3)
  }
}
</script>