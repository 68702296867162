<template>
  <h2 class="title">Editing record:</h2>
  <div class="container" v-if="!loading">
    <form>
      <div class="field">
        <label class="label">Album artist:</label>
        <input class="input" type="text" v-model="album.attributes.display_artist" />
      </div>
      <div class="field">
        <label class="label">Album artist sort field:</label>
        <input class="input" type="text" v-model="album.attributes.sort_display_artist" />
      </div>
      <div class="field">
        <label class="label">Album title:</label>
        <input class="input" type="text" v-model="album.attributes.name" />
      </div>
      <div class="field">
        <label class="label"># of discs:</label>
        <input class="input" @keyup.enter="submitAlbum" type="text" v-model="album.attributes.number_of_discs" />
      </div>
      <div class="field">
        <label class="label">Year:</label>
        <input class="input" @keyup.enter="submitAlbum" type="text" v-model="album.attributes.year" />
      </div>
      <div class="file" v-if="!cover">
        <label class="file-label">
          <input class="file-input" type="file" name="cover" @change="onFileChange">
          <span class="file-cta">
            
            <span class="file-label">
              Choose a cover image
            </span>
          </span>
        </label>
      </div>
      <div v-else>
        <img :src="cover" />
        <button type="button" class="button is-warning" @click="removeImage">Remove image</button>
      </div>
      <div class="field">
        <label class="label">
          <input class="checkbox" type="checkbox" v-model="album.attributes.is_comp" />
          Is this a compilation?
        </label>
          
      </div>
      <div class="field">
        <label class="label">
          <input class="checkbox" type="checkbox" v-model="album.attributes.spoken_word" />
          Is this a podcast or spoken word content??
        </label>
      
      </div>
      <div class="columns">
        <div class="column is-half">
          <div class="field">
            <label class="label">Discogs master ID:</label>
             <input class="input" @keyup.enter="submitAlbum" type="text" v-model="album.attributes.discogs_master_id" />
          </div>
        </div>
        <div class="column is-half">
          <div class="field">
            <label class="label">Discogs release ID:</label>
             <input class="input" @keyup.enter="submitAlbum" type="text" v-model="album.attributes.discogs_release_id" />
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div class="buttons is-grouped">
            <router-link  class="button is-outlined" @click="closeEditor" :to="{name: 'EditTracks', params: {id: album.id}}">Edit metadata for {{ album.attributes.tracks_count }} track(s)</router-link>
            <button type="button" class="button is-outlined" @click="ismerging = !ismerging"> Merge others into this</button>
          </div>
        </div>
      </div>
      <div class="columns" v-show="ismerging">
        <div class="column">
          Search for items to merge into this record (id {{ album.id }}). The originals will be deleted and their tracks moved to this album.
        </div>
        <div class="column">
          <div class="field">
            <input class="input" type="text" v-model="mergeSearch" placeholder="Search...." />
            <span v-if="mergeList.length > 0">Will merge in albums: {{ mergeList }}</span>
          </div>
        </div>
        <div class="column" v-if="!mergeSearching">
          <div v-if="noResults">
            Sorry, no matches. Try again.
          </div>
          <table class="table merge_results" v-else>
            <tr @click="toggleToBeMerged(hit.id)" :class="mergeList.includes(hit.id) ? 'selected' : ''" v-for="hit in mergeSearchResults" v-show="hit.id != album.id" :key="hit.id">
              <td>{{ hit.attributes.display_artist }}</td>
              <td>{{ hit.attributes.name }}</td>
              <td>{{ hit.attributes.year }}</td>
              <td>{{ hit.attributes.tracks_count }} </td>
            </tr>
          </table>
        </div>
        <div class="loading has-text-centered" v-else>
          <img src="@/assets/images/loading.gif" />
        </div>
      </div>

      <div v-if="!submitting">
        <div class="field" v-if="album.attributes.discogs_release_id">
          <label class="label">
            <input class="checkbox" type="checkbox" v-model="album.attributes.resync_image" />
            Get new image from discogs release id #{{ album.attributes.discogs_release_id }}
          </label>
        
        </div>
        <div class="field">
          <label class="label">
            <input class="checkbox" type="checkbox" v-model="album.attributes.ignore_timestamp" />
            Do not update the 'last modified' timestamp
          </label>
            
        </div>
        <div class="buttons ">
          <button class="button is-warning" @keydown.esc="closeEditor" @click="closeEditor">Cancel</button>
          <button class="button is-primary" @click="submitAlbum">Save</button>
        </div>
        <div class="buttons is-right">
          <button class="button is-danger is-right" @click="destroyAlbum(album.id)">Delete</button>
        </div>
      </div>
      <div class="loading has-text-centered" v-else>
        <img src="@/assets/images/loading.gif" />
      </div>
    </form>
  </div>
  <div class="loading has-text-centered" v-else>
    <img src="@/assets/images/loading.gif" />
  </div>

</template>

<script>
import { useToast } from 'vue-toastification'
import _ from 'lodash'
export default {
  data() {
    return {
      cover: null,
      album: {},
      submitting: false,
      ismerging: false,
      loading: true,
      searching: false,
      isTyping: false,
      mergeList:  [],
      mergeSearchResults: [],
      noResults: false,
      mergeSearch: null,
      mergeSearching: false
    }
  },
  emits: ['closeEditor', 'keyup'],
  props: ['albumId'],
  methods: {
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.createImage(files[0]);
    },
    createImage(file) {
      // var image = new Image();
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.cover = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    removeImage: function () {
      this.cover = '';
    },
    closeEditor (arg, id) {
      this.loading = true
      // console.log('arg should pass is ', arg )
      // console.log('id to pass is ', id)
      this.$emit('closeEditor', arg, id)
      this.album = {}
    },
    searchForMergers () {
      this.searching = true;
			this.axios.get('/v1/albums/search', { params: { search: this.mergeSearch, page: 1 }})
        .then((hits) => {
          this.searching = false;
          if (hits.data.data.length > 0) {
            this.noResults = false
            this.mergeSearchResults = []
            hits.data.data.forEach((entry) => {
              this.mergeSearchResults.push(entry)
            })
          } else { 
            this.noResults = true
            this.isTyping = false
          }
        })
    },
    destroyAlbum(albumId) {
      if (confirm('Are you sure you want to delete this album? All of its tracks will be deleted as well.')) {
        this.axios.delete('/v1/albums/' + albumId)
          .then((resp) => {
            if (resp.status === 204) {
              const toast = useToast()
              toast.error('The album has been deleted.', {
                timeout: 4000
              })
              this.closeEditor('deleted', albumId)
            } else { 
              this.submitting = false
            }
          }).catch((err) => {
            this.submitting = false
            const toast = useToast()
            toast.error('There was an error deleting the album: ' + err.response.data.message, {
              timeout: 4000
            })
        })
      }
    },
    submitAlbum () {
      this.submitting = true
      let payload = {"ignore_timestamp": this.album.attributes.ignore_timestamp, "resync_image": this.album.attributes.resync_image, "spoken_word": this.album.attributes.spoken_word,  "display_artist": this.album.attributes.display_artist, "discogs_master_id": this.album.attributes.discogs_master_id, "discogs_release_id": this.album.attributes.discogs_release_id, "sort_display_artist": this.album.attributes.sort_display_artist, "number_of_discs": this.album.attributes.number_of_discs, "name": this.album.attributes.name, "is_comp": this.album.attributes.is_comp, "year": this.album.attributes.year, "merge_ids": this.mergeList, "cover": {"data": this.cover }}
      this.axios.put('/v1/albums/' + this.albumId, payload)
        .then((resp) => {
          this.submitting = false
          if (resp.status === 200) {
            console.log('all good')
            this.closeEditor('edited', resp.data.data.id)
            const toast = useToast()
            toast.success('The album has been updated.', {
              timeout: 4000
            })
          } else { 
            this.submitting = false
          }
        }).catch((err) => {
            this.submitting = false
            console.log(err)
        })
    },
    toggleToBeMerged(item) {
      let exists = this.mergeList.includes(item)
      if (exists) {
        return this.mergeList.filter((c) => { return c !== item })
      } else {
        this.mergeList.push(item)
      }
    }
  },
  mounted () {
    
  },
  watch: {
    albumId: function(newVal) {
      if (newVal) {
        this.mergeSearchResults = []
        this.axios.get('/v1/albums/' + newVal)
          .then((resp) => {
            // console.log(resp.data.data)
            this.album = resp.data.data
            this.loading = false
            this.cover = resp.data.data.attributes.cover
            this.ismerging = false
            this.mergeSearch = null
            this.mergeList = []
            document.onkeydown = e => {
              if (e.keyCode === 27) {
                console.log('escape key pressed editalbum')
                this.$emit('closeEditor', null, resp.data.data.id)
                document.onkeydown = null
              }
              else if (e.keyCode === 13) {
                this.submitAlbum()
              }
            }
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        console.log('clearing old album in editor')
        this.loading = true
      }
    },
    mergeSearch: _.debounce(function() {
      this.isTyping = true;
      if (this.mergeSearch === '') {
        if (this.noResults) {
          this.noResults = false
        }
        this.isTyping = false
      }
    }, 600),
    isTyping: function(value) {

      if (value) {
        this.searchForMergers(this.searmergeSearchch);
        this.isTyping = false
      } else {
        console.log('no value!')
      }
    }
  }
}
</script>
