import {createAuth, useAuth}          from '@websanova/vue-auth/src/v3.js';
import driverDevise      from '@websanova/vue-auth/dist/drivers/auth/devise.esm.js';
import driverHttpAxios       from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';


export default (app) => {
    app.use(createAuth({
        plugins: {
            http: app.axios,
            router: app.router,
        },
        drivers: {
            http: driverHttpAxios,
            auth: driverDevise,
            router: driverRouterVueRouter,

        },
        options: {

          loginData: { 
            url: app.axios.defaults.baseURL + '/auth/sign_in', 
            method: 'POST', 
            redirect: '/browse'
          },
          logoutData: {
            url: app.axios.defaults.baseURL + '/auth/sign_out', 
            method: 'DELETE',
            redirect: '/'
          },
          fetchData: {
            enabled: true,
            url:  process.env.VUE_APP_AUTH_PATH + '/auth/validate_token', method: 'GET',
            interval: 5,
            success (res) {
              console.log('success')
              console.log(res.headers)
            }
          },
          
        
          refreshData: {
            enabled: true,
            url:  process.env.VUE_APP_AUTH_PATH + '/auth/validate_token', method: 'GET',
            interval: 5,
          },
          rolesKey: 'type',
          notFoundRedirect: {name: 'user-account'},
        }
    }));
}

export { useAuth }