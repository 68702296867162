<template>
  <table class="table albumPlayer">
    <Track v-for="track in tracks" :dl="true" :key="track.id" :nowPlaying="nowPlaying == track ? true : false" :currentAlbum="currentAlbum" :track="track"  @playTrack="playTrack" />
  </table>
</template>

<script>
import Track from '@/components/Track'
export default {
  props: {
    tracks: Array,
    nowPlaying: Object,
    currentAlbum: Object
  },
  components: { Track },
 
  methods : {

    playTrack(e) {
      console.log('got emitted up from track compontent, in Tracklist')
      this.$emit('playTrack', e)
    },
  }
}
</script>
