import { createRouter, createWebHistory } from 'vue-router'

function loadView(view) {
  return () => import(`../components/${view}.vue`)
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/browse',
      name: 'Browse',
      component: loadView('Browse'),
      meta: {
        auth: true
      }
    },
    {
      path: '/missing',
      name: 'Missing',
      component: loadView('Missing'),
      meta: {
        auth: true
      }
    },
    {
      path: '/bad_format',
      name: 'BadFormat',
      component: loadView('WrongFormat'),
      meta: {
        auth: true
      }
    },
    {
      path: '/album/:id',
      name: 'Album',
      component: loadView('Album'),
      meta: {
        auth: true
      }
    },
    {
      path: '/radio',
      name: 'Radio',
      component: loadView('Radio'),
      meta: {
        auth: true
      }
    },
    {
      path: '/album/:id/edit_tracks',
      name: 'EditTracks',
      component: loadView('EditTracks'),
      meta: {
        auth: true
      }
    },
    {
      path: '/upload',
      name: 'Upload',
      component: loadView('Upload'),
      meth: {
        auth: true
      }
    },
    {
      path: '/login',
      name: 'auth-login',
      component: loadView('Login'),
      meta: {
        auth: false
      }
    }
  ]
})

export default (app) => {
  app.router = router

  app.use(router)
}
