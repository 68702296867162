<template>
  <div v-if="!loading && currentAlbum">
    <div id="player_inner" class="is-one-third-desktop" :class="minimised ? 'mini' : ''" v-if="currentAlbum.id">
      <div class="is-hidden-mobile">
        <button id="close_album" @click="stopListening" class="delete is-medium">&times;</button>
      </div>     
      <div class="album_metadata">
        <div class="is-hidden-tablet is-pulled-right	">
          <button id="toggle_minimisedt" @click="minimise" class="button is-primary is-small"><span v-if="minimised">&#8593;</span><span v-else>&#8595;</span></button>
          <button id="close_album_from_minimisedt" @click="stopListening" class="button is-danger is-small">&times;</button>
        </div>
        <h1 class="title">{{ currentAlbum.attributes.display_artist }}</h1>
      
        <h2 class="subtitle">
          <em>{{ currentAlbum.attributes.name }}</em> 
          <br />{{ currentAlbum.attributes.year }}
          <span v-if="currentAlbum.attributes.discogs_master_id || currentAlbum.attributes.discogs_release_id">
            &nbsp;&sdot;&nbsp;
            <a v-if="parseInt(currentAlbum.attributes.discogs_master_id) > 0" :href="'https://www.discogs.com/master/' + currentAlbum.attributes.discogs_master_id" target="_blank" ><font-awesome-icon icon="external-link-alt" /></a>
            <a v-else  :href="'https://www.discogs.com/release/' + currentAlbum.attributes.discogs_release_id"
              target="_blank"><font-awesome-icon icon="external-link-alt" />
            </a> &nbsp;&sdot;&nbsp;
          </span>
          <a @click="metadataVisible = !metadataVisible">
            <span v-if="metadataVisible">&uarr;</span><span v-else>&darr;</span>
          </a>
          <div v-if="metadataVisible && agents" class="content is-size-7">
            <img :src="'https:' + getAgentIcon(currentAlbum.attributes.added_by_id)" width="35" />
            Added by {{ getAgent(currentAlbum.attributes.added_by_id).attributes.surname}}
            on {{ moment(currentAlbum.attributes.created_at).format('Do MMMM YYYY') }}
          </div>
        </h2>
        <div class="level">

          <div class="level-left">
            
          </div>
        </div>
        
      </div>
      <div class="columns player_start">
        <div class="column" :class="minimised ? 'is-one-quarter-mobile is-full-tablet' : 'is-full'">
          <figure class="image is-square">
            <img v-if="currentAlbum.attributes.cover" :src="currentAlbum.attributes.cover" />
            <img v-else src="@/assets/images/missing.jpg" />
          </figure>
        </div>
        <div class="column is-three-quarters-mobile is-full-tablet">
          <div v-if="minimised" class="is-hidden-tablet is-pulled-right	">
            <button id="toggle_minimised" @click="minimise" class="button is-primary is-small"><span v-if="minimised">&#8593;</span><span v-else>&#8595;</span></button>
            <button id="close_album_from_minimised" @click="stopListening" class="button is-danger is-small">&times;</button>
          </div>

          <div class="mobile_title">
            <h1 class="title">{{ currentAlbum.attributes.display_artist }}</h1>
            <h2 class="subtitle"><em>{{ currentAlbum.attributes.name }}</em> &sdot; {{ currentAlbum.attributes.year }}</h2>
            <div v-if="minimised">
              <button :disabled="!hasPrev" @click="playPrevTrack" class="button is-primary">&#8592;</button>
              <button :disabled="!hasNext" @click="playNextTrack" class="button is-primary">&#8594;</button>
            </div>
            <span v-if="!minimised && (currentAlbum.attributes.discogs_master_id || currentAlbum.attributes.discogs_release_id)">
              &nbsp;&sdot;&nbsp;
              <a v-if="currentAlbum.attributes.discogs_master_id" :href="'https://www.discogs.com/master/' + currentAlbum.attributes.discogs_master_id" target="_blank" ><font-awesome-icon icon="external-link-alt" /></a>
              <a v-else  :href="'https://www.discogs.com/release/' + currentAlbum.attributes.discogs_master_id"
                target="_blank"><font-awesome-icon icon="external-link-alt" />
              </a>                  
            </span>

          </div>
        </div>
      </div>
      <div class=" player_body">
        <div class=" loading has-text-centered" v-if="loadingTrack">
          <img src="@/assets/images/loading.gif" />
        </div>
        <div v-else-if="tracks.length === 0">No tracks.</div>

          <table class="table currentSong albumPlayer" v-if="nowPlaying.attributes.songfile">
            <Track :dl="false" :key="'playing_' + nowPlaying.id" :nowPlaying="true"  :currentAlbum="currentAlbum" :track="nowPlaying"  />
          </table>
   

          <Player theme="light" v-if="nowPlaying" ref="pbtPlayer" controls  autoplay
              @vmReady="onPlaybackReady"
              @vmPlaybackEnded="endOfTrack"
              @vmPlaybackReady="mediaReady"
              >
              
            <Audio cross-origin preload>
              <source :data-src="nowPlaying.attributes.songfile" :type="fixType(nowPlaying.attributes.contentType)" />
            </Audio>
            
          </Player>
   
      </div>

      <div class="columns  track_list is-mobile">
        <div class="column">

          <Tracklist :currentAlbum="currentAlbum" v-if="tracks.length > 0" :nowPlaying="nowPlaying" :tracks="tracks"  @playTrack="playTrack" />
          <table class="queue_table table" v-if="onDeck.length > 0">
            <thead>
              <th colspan="2">
                <strong><em>Up next:</em></strong>
              </th>
              <td>
                <button class="button is-small is-danger is-outlined" @click="clearQueue">Clear all</button>
              </td>
            </thead>
            <tr v-for="queueItem in onDeck" :key="queueItem.id">
              <td>
                <img v-if="queueItem.attributes.cover" :src="queueItem.attributes.cover" width="100" />
                <img v-else src="@/assets/images/missing.jpg" width="100"/>
              </td>
              <td>
                <h2 class="artist">{{ queueItem.attributes.display_artist }}</h2>
                <h2 class="album"> {{ queueItem.attributes.name }} </h2>
                <h4 class="year"><span v-if="queueItem.attributes.year">{{ queueItem.attributes.year }}&nbsp;&sdot;&nbsp;</span><span v-if="queueItem.attributes.number_of_discs > 1">{{ queueItem.attributes.number_of_discs }} discs&nbsp;&sdot;&nbsp;</span><span v-if="queueItem.attributes.tracks_count">{{queueItem.attributes.tracks_count }}&nbsp;<span v-if="queueItem.attributes.tracks_count > 1">tracks</span><span v-else>track</span></span></h4>
              </td>
              <td>
                <button v-if="currentAlbum" class="edit_album button is-small" @click="removeFromQueue(queueItem.id)">Remove</button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="loading has-text-centered" v-else>
      <img src="@/assets/images/loading.gif" />
    </div>
  </div>
  <div class="loading has-text-centered" v-else>
    <img src="@/assets/images/loading.gif" />
  </div>
</template>
<script>
import { useToast } from 'vue-toastification'
import normalize from 'json-api-normalizer'
import Tracklist from '@/components/Tracklist'
import Track from '@/components/Track'
import { Player, Audio } from '@vime/vue-next'

export default {
  name: 'Album',
  props: { 
    'agents': Array,
    'album': Object,
    'queue': Array
  },
  emits: ['albumLoaded', 'minimise'],
  data() {
    return {
      metadataVisible: false,
      currentAlbum: {},
      rawTracks: {},
      tracks: [],
      minimised: false,
      loading: true,
      nowPlaying: null,
      hasNext: true,
      hasPrev: false,
      loadingTrack: true,
      onDeck: [],
      moment: this.$moment
    }
  },
  components: {
    Player,
    Track,
    Tracklist,
    Audio,
  },
  computed: {
    player() {
      return this.$refs.pbtPlayer;
    },
  },
  methods : {
    getAgent(agentId) {
      if (this.agents) {
        let agent = this.agents.filter((a) => parseInt(a.id) === parseInt(agentId))
        // console.log(agent[0])
        return agent[0]
      } else {
        return {added_by_id: 0}
      }
    },
    getAgentIcon(agentId) {
      if (this.agents) {
        let agent = this.agents.filter((a) => parseInt(a.id) === parseInt(agentId))
        // console.log(agent[0])
        return agent[0].attributes.icon
      } else {
        return ''
      }
    },
    fixType(contentType) {
      return contentType.replace(/x-flac/, 'flac').replace(/mp4$/, 'x-m4a')
    },
    removeFromQueue(albumId) {
      let q = localStorage.getItem('queue')
      q = JSON.parse(q).filter(function( obj ) {
        return obj.id !== albumId
      })
      // console.log(q)
      localStorage.setItem('queue', JSON.stringify(q))
      this.parseQueue()
    },
    clearQueue () {
      this.onDeck = []
      localStorage.removeItem('queue')
    },
    stopListening () {
      this.$emit('stopListening', true)
    },
    minimise () {
      this.minimised = !this.minimised
      this.$emit('minimise', this.minimised)
    },
    async connectChannel (headers) {
      await this.$cable.connection.connect(() => `${ process.env.VUE_APP_CABLE_URL }?uid=${ headers.uid }&client=${ headers.client }&access-token=${ headers['access-token'] }` )
      await this.$cable.subscribe({ channel: 'StreamChannel' })
    },
    mediaReady(f) {
      this.loadingTrack = false
      // console.log('emitted mediaReady::')
      // console.log(f)
      if (f) {
        // console.log('starting next play')
        this.$refs.pbtPlayer.play()
      }
    },
    playPrevTrack() {
      let currentIndex = this.tracks.map((e) => {
        return e.id == this.nowPlaying.id
      }).indexOf(true)
      let prevTrack = currentIndex - 1
      if (this.tracks[prevTrack]) {
        this.playTrack(this.tracks[prevTrack].id, 'next')
        this.hasNext = true
        if (!this.tracks[prevTrack - 1]) {
          this.hasPrev = false
        }
      } else {
        this.hasPrev = false
      }
    },
    playNextTrack() {
      let currentIndex = this.tracks.map((e) => {
        return e.id == this.nowPlaying.id
      }).indexOf(true)
      let nextTrack = currentIndex + 1
      if (this.tracks[nextTrack]) {
        this.hasPrev = true
        this.playTrack(this.tracks[nextTrack].id, 'next')
      } else if (this.onDeck.length > 0) {
        this.endOfTrack(true)
      } else {
        this.hasNext = false
      }

    },
    endOfTrack(f) {
      if (f) {
        // console.log('emitted endoftrack naturally:')
        this.loadingTrack = true
        // console.log(JSON.stringify(f))
        let currentIndex = this.tracks.map((e) => {
            return e.id == this.nowPlaying.id
          }).indexOf(true)
        let nextTrack = currentIndex + 1
        // console.log('should be going from  ' + currentIndex + ' to ' + nextTrack)
        if (this.tracks[nextTrack]) {

          this.playTrack(this.tracks[nextTrack].id, 'next')
        } else {
          // console.log('reached the end of the playlist, check queue')
          if (this.onDeck.length > 0) {
            this.axios.get('/v1/albums/' + this.onDeck[0].id)
              .then((resp) => {
                if (resp.status === 200) {
                  // console.log('emitting albumLoaded to parent App')
                  this.removeFromQueue(this.onDeck[0].id)
                  this.$emit('albumLoaded', resp.data)
                  this.hasPrev = false
                } else if (resp.status === 404) {
                  // console.log('Album not found')
                }
              })
              .catch((error) => {
                console.log(error)
              })

          } else {
            this.hasNext = false
            this.$emit('stopListening', true)
          }
        }
        // }
        // return false
      }

    },
    onPlaybackReady () {
      // console.log('playback ready, start track')
      this.playTrack()
    },
    prepareTracks (newAlbum) {
      this.rawTracks = normalize(newAlbum).track
      this.tracks = []
      if (this.rawTracks) {
        Object.keys(this.rawTracks).forEach((t) => this.tracks.push(this.rawTracks[t]))
        this.tracks.sort((a, b) => (a.attributes.discNumber > b.attributes.discNumber) ? 1 : (a.attributes.discNumber  === b.attributes.discNumber ) ? ((a.attributes.trackNumber  > b.attributes.trackNumber ) ? 1 : -1) : -1)

        this.nowPlaying = this.tracks[0]
      } else {
        const toast = useToast()
        toast.warning('This album does not contain any tracks.', {
          timeout: 4000
        })
        this.tracks = []
        this.loadingTrack = false
        
      }
    },
    playTrack(e, state) {
      // this.$refs.pbtPlayer.pause()
      if (e) {
        // console.log('updating nowplaying to track ' + JSON.stringify(e))

        this.nowPlaying = this.rawTracks[e]
        localStorage.setItem('queueTop', JSON.stringify({albumId: this.currentAlbum.id, track: e }))
      }
      let currentIndex = this.tracks.map((e) => {
        return e.id == this.nowPlaying.id
      }).indexOf(true)
      if (!this.tracks[currentIndex + 1]) {
        if (!this.onDeck.length > 0) {
          this.hasNext = false
        }
      }
      if (this.tracks[currentIndex - 1]) {
        this.hasPrev = true
      }
      if (!state) {
        let playPromise = this.$refs.pbtPlayer.play()
        if (playPromise !== undefined) {
          playPromise.then(function() {
            // console.log('Auto matic playback started!')
          }).catch(function(error) {
            // console.log('Auto matic playback failed!')
            console.log(error)
            // Show a UI element to let the user manually start playback.
          })
        } 
      } else {
        console.log('state was passed')
        console.log(state)
      }
    },
    parseQueue() {
      let queue = localStorage.getItem('queue')
      let queueTop = localStorage.getItem('queueTop')
      this.onDeck = []
      if (queue) {
        this.onDeck = JSON.parse(queue)
      }
      if (queueTop) {
        let qt = JSON.parse(queueTop)
        if (this.currentAlbum.id === qt.albumId) {
          // console.log('already opened this album')
          // console.log('should skip to track ' + qt.track)

          this.nowPlaying = this.rawTracks[qt.track]
        }
      }
    }
    
    
  },
  mounted () {
    this.currentAlbum = this.album.data
    this.prepareTracks(this.album)
    this.loading = false
    this.parseQueue()
    
  },
  watch: {
    queue (old, newQueue) {
      if (old.length !== newQueue.length) {
        this.parseQueue()
      }
    },
    album (newAlbum, old) {
      // console.log('old album: ' + old.data.attributes.name)
      // console.log('current: ' + this.currentAlbum.attributes.name)
      // console.log('new album: ' + newAlbum.data.attributes.name)
      if (old !== newAlbum) {
       
        this.currentAlbum = newAlbum.data
        this.prepareTracks(newAlbum)
        this.loading = false
      }
    }
  }
}
</script>
